@font-face {
  font-family: "Poppins-Light";
  src: url(./fonts/Poppins-Light.ttf);
}
@font-face {
  font-family: "Poppins-Medium";
  src: url(./fonts/Poppins-Medium.ttf);
}
@font-face {
  font-family: "LibreBodoni-Italic";
  src: url(./fonts/LibreBodoni-Italic.ttf);
}

body {
  font-family: "Poppins-Light" !important;
  font-size: 18px;
  color: #6f6f72;
}

/*Weight 300 */
.peso-light {
  font-family: "Poppins-Light";
}
/*Weight 500 */
.peso-medium {
  font-family: "Poppins-Medium";
}
/*Weight 400 italic */
.peso-italic {
  font-family: "LibreBodoni-Italic";
}

.tamaño-mas-grande {
  font-size: 30px;
}

.tamaño-grande {
  font-size: 20px;
}

.tamaño-medio {
  font-size: 15px;
}

.tamaño-comentario {
  font-size: 15px;
}
@media (max-width: 770px) {
  .tamaño-comentario {
    font-size: 14px;
  }
}

.tamaño-chico {
  font-size: 14px;
}

.tamaño-mas-chico {
  font-size: 10px;
}

.color-blanco-fondo {
  background-color: #ffffff;
}

.color-blanco-oscuro-fondo {
  background-color: #f5f5f5;
}

.color-gris-claro-fondo {
  background-color: #8e8d8d;
}

.color-gris {
  color: #6f6f72;
}

.color-gris-fondo {
  background-color: #6f6f72;
}

.color-negro {
  color: #464646;
}

.color-negro-fondo {
  background-color: #464646;
}
.sin-sub {
  text-decoration: none !important;
}

.interlineado {
  line-height: 20px;
}
.interlineado2 {
  line-height: 40px;
}

.btn-outline-secondary {
  border-radius: 0;
}
.btn-outline-secondary:hover {
  background-color: #6f6f72;
  color: #ffffff;
}
.btn-outline-secondary:active {
  background-color: #6f6f72;
  color: #ffffff;
}
.btn-outline-secondary:focus {
  background-color: #6f6f72;
  color: #ffffff;
}

/*Banner*/
.banner-tam {
  max-height: 84vh;
}

/* Visitantes */
.caja-card {
  position: absolute;
  width: 295px;
  margin-top: -45px;
  border: 1px solid #6f6f72 !important;
}

/* Card Reserva*/
.card-reserva {
  width: 977px;
  margin: 0 auto;
}

.boton-reserva {
  border: 0;
  border-radius: 0;
}
.boton-reserva:hover {
  background-color: #464646;
}
.boton-reserva:active {
  background-color: #464646;
}
.boton-reserva:focus {
  background-color: #464646;
}

.iconos-reservar {
  width: 70px !important;
}

/*Comentarios*/
.fondo-card {
  background-color: #f5f5f5;
}

.margen-comentario {
  margin-left: 100px !important;
}

.foto-comentario {
  max-width: 40px;
  max-height: 40px;
}

.toast {
  width: 450px;
  box-shadow: none;
}

.fondo-card .btn-close {
  display: none;
}

/* Descubre */
.descubre-fotos-alto {
  height: 300px;
}

.descubre-fotos-ancho1 {
  width: 625px !important;
}
.descubre-fotos-ancho2 {
  width: 305px !important;
}
.descubre-fotos-ancho3 {
  width: 450px !important;
}
.descubre-fotos-ancho4 {
  width: 250px !important;
}
.descubre-fotos-ancho5 {
  width: 385px !important;
}
.descubre-fotos-ancho6 {
  width: 250px !important;
}

@media (max-width: 770px) {
  .margen-comentario {
    margin-left: 0px !important;
  }
  .descubre-fotos-alto {
    height: 250px;
  }
  .descubre-fotos-ancho1 {
    width: 370px !important;
  }
  .descubre-fotos-ancho2 {
    width: 253px !important;
  }
  .descubre-fotos-ancho3 {
    width: 374px !important;
  }
  .descubre-fotos-ancho4 {
    width: 400px !important;
  }
  .descubre-fotos-ancho4 {
    width: 250px !important;
  }
  .descubre-fotos-ancho5 {
    width: 385px !important;
  }
  .descubre-fotos-ancho6 {
    width: 250px !important;
  }
}

/*  Navbar - banderas */

.banderas {
  width: 25px;
}

/* Productos */

.tamaño-caja {
  height: 60px;
}

.productos-cards {
  width: 17%;
  /* width: 18rem;  /* ANTES DEL CAMBIO*/
}

@media (max-width: 770px) {
  .productos-cards {
    width: 30%;
  }
}

@media (max-width: 440px) {
  .productos-cards {
    width: 45%;
  }
}

/* Ofertas */

.card-ofertas {
  width: 75%;
}

@media (max-width: 768px) {
  .card-ofertas {
    width: 100%;
  }
}

/*Cursor sobre comentarios*/
.cursor {
  cursor: pointer;
}

/* imagen banner cajas*/
.caja-imagen-banner {
  width: 100%;
  height: auto;
}
.caja-imagen-banner2 {
  width: 75%;
  height: auto;
}

@media (max-width: 768px) {
  .caja-imagen-banner {
    width: 80%;
    height: auto;
  }
  .caja-imagen-banner2 {
    width: 50%;
    height: auto;
  }
}

@media (max-width: 425px) {
  .caja-imagen-banner {
    max-width: 100%;
    height: auto;
  }
  .caja-imagen-banner2 {
    width: 80% !important;
    height: auto;
  }
}

/*icono reserva*/
.icono-reserva {
  width: 230px;
  transition-property: all;
  transition-duration: 0.5s;
}
.icono-reserva:hover {
  width: 240px;
  transition-property: all;
  transition-duration: 0.5s;
}
@media (max-width: 768px) {
  .icono-reserva {
    width: 160px;
  }
  .icono-reserva:hover {
    width: 180px;
  }
}

@media (max-width: 425px) {
  .icono-reserva {
    width: 120px;
  }
  .icono-reserva:hover {
    width: 140px;
  }
}

.margen-reserva {
  margin-top: 5rem;
}

.margen-reserva2 {
  margin-top: 2.5rem;
}

@media (max-width: 1024px) {
  .margen-reserva {
    z-index: 3 !important;
  }

  .margen-reserva2 {
    z-index: 1030 !important;
  }
}

.ocultar-div {
  overflow: hidden !important;
}
